import React from 'react'
import CompanyPayments from '../All Transactions/Pages/CompanyPayments'

const CompanyPayouts = () => {
  return (
    <div>
      <CompanyPayments/>
    </div>
  )
}

export default CompanyPayouts
