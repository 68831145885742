import React from 'react'
import { PaymentsTable } from './table/paymentsTable'
import { KTCard } from '../../../_metronic/helpers'

const Payments = () => {
  return (
    <div>
      payments
      <KTCard>
      
      <PaymentsTable/>
      </KTCard>
      
    </div>
  )
}

export default Payments
