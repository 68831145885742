import React from 'react'

const ErrorLogs = () => {
  return (
    <div>
      Errorlogs
    </div>
  )
}

export default ErrorLogs
