import React from 'react'

const DefaultSettings = () => {
  return (
    <div>
      DefaultSettings
    </div>
  )
}

export default DefaultSettings
